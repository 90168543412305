import React, { useState } from 'react';
import '../HomeBanner.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track if the menu is open

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu state
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <h1>The First 24</h1>
      </div>
      <div className="hamburger" onClick={toggleMenu}> {/* Use React onClick event */}
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>  
      <ul className={`navbar-links ${isOpen ? 'show' : ''}`}> {/* Toggle the 'show' class based on isOpen state */}
        <li>
          <a href="/home">Home</a>
        </li>
        <li className="hire-link">
          <a href="/hire-with-us">Hire with us</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
