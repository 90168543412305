import React, { useEffect, useState } from 'react';
import axios from 'axios';
import JobItem from './JobItem';
import JobFilter from './JobFilter';  // Import the JobFilter component

const JobList = () => {
  const [jobs, setJobs] = useState([]);  // All jobs fetched from the API
  const [displayedJobs, setDisplayedJobs] = useState([]);  // Jobs currently being shown
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(20);  // Number of jobs to display initially
  const [filters, setFilters] = useState({ titleKeyword: '', location: '' });  // Store selected filters
  const [totalFilteredJobs, setTotalFilteredJobs] = useState(0);  // Total number of filtered jobs

  // Keyword mapping to handle variations
  const keywordMapping = {
    frontend: ['frontend', 'front end', 'front-end'],
    backend: ['backend', 'back end', 'back-end'],
    ios: ['ios', 'iOS', 'swift'],
    android: ['android'],
    designer: ['designer', 'design'],
    data: ['data'],
    program: ['program'],
    quality: ['quality', 'qa', 'test'],
    hardware: ['hardware']
    // Add more mappings here
  };

  // Fetch jobs from API
  useEffect(() => {
    const fetchJobs = async () => {
      const response = await axios.get('https://dry-mesa-96603-3b72f6dd77e9.herokuapp.com/api/jobs');
      setJobs(response.data);  // Store all fetched jobs
      setDisplayedJobs(response.data.slice(0, visibleCount));  // Initially display only 20 jobs
      setLoading(false);
    };
    fetchJobs();
  }, [visibleCount]);

  // Apply filters and show all matching jobs
  useEffect(() => {
    const filteredJobs = [];
  
    jobs.forEach((job) => {
      const jobLocations = Array.isArray(job.location) ? job.location : [job.location];  // Ensure job.location is an array
      const locationNormalized = jobLocations.map(loc => loc.toLowerCase().replace(/[- ]/g, ''));
  
      const title = job.title.toLowerCase().replace(/[- ]/g, ''); // Normalize the job title
  
      // Only match the selected titleKeyword from filters, not all keywords in the mapping
      if (filters.titleKeyword) {
        const variations = keywordMapping[filters.titleKeyword.toLowerCase()];
        if (variations) {
          const matched = variations.some((variation) =>
            title.includes(variation.replace(/[- ]/g, ''))
          );
  
          // Check if title matches the selected filter and location matches the filter
          if (matched && (filters.location === '' || locationNormalized.some(loc => loc.includes(filters.location.toLowerCase().replace(/[- ]/g, ''))))) {
            filteredJobs.push(job);
          }
        }
      } else {
        // If no titleKeyword filter is applied, just check location
        if (filters.location === '' || locationNormalized.some(loc => loc.includes(filters.location.toLowerCase().replace(/[- ]/g, '')))) {
          filteredJobs.push(job);
        }
      }
    });
  
    // Update the total number of jobs matching the filters
    setTotalFilteredJobs(filteredJobs.length);

    // If filters are applied, show all matching jobs, otherwise apply pagination
    if (filters.titleKeyword || filters.location) {
      setDisplayedJobs(filteredJobs); // Show all filtered jobs
    } else {
      setDisplayedJobs(filteredJobs.slice(0, visibleCount)); // Apply pagination if no filters
    }
  }, [filters, jobs, visibleCount, keywordMapping]);  // Add keywordMapping here
  
  // Load more jobs on button click
  const loadMoreJobs = () => {
    setVisibleCount(visibleCount + 20);  // Increase the number of jobs to display by 20
  };

  // Handle filter change
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className="job-list">
      {/* Display the total jobs count based on filters */}
      
      <JobFilter onFilterChange={handleFilterChange} />  {/* Add the filter component */}
      <p className="jobs-number">{totalFilteredJobs} Jobs Available</p>  {/* This will display the total number of jobs based on the current filters */}
      
      {loading ? (
        <p>Loading jobs...</p>
      ) : (
        <>
          {displayedJobs.length === 0 ? (
            <p className='no-jobs-message'>No jobs matching that criteria.</p>
          ) : (
            displayedJobs.map((job, index) => <JobItem key={index} job={job} />)
          )}
          {visibleCount < jobs.length && !filters.titleKeyword && !filters.location && displayedJobs.length > 0 && (
            <button onClick={loadMoreJobs} className="load-more-btn">
              Load More Jobs
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default JobList;
