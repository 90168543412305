import React from 'react';
import NavBar from './NavBar';
import JobList from './JobList';
import Footer from './Footer';
import HomeBanner from './HomeBanner';

function Home() {
  return (
    <>
      <div className="home-header">
        <NavBar />
        <HomeBanner />
      </div>
      <main>
        <JobList />
      </main>
      <Footer />
    </>
  );
}

export default Home;