import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../LandingPage.css'

const LandingPage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [time, setTime] = useState(new Date());
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);

    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(timer);
    };
  }, []);

  const hours = time.getHours().toString().padStart(2, '0');
  const minutes = time.getMinutes().toString().padStart(2, '0');

  const handleBeginSearch = () => {
    setIsExiting(true);
    setTimeout(() => {
      navigate('/home');
    }, 500); // This should match the transition duration in CSS
  };

  return (
    <div className={`landing-page ${isExiting ? 'fade-out' : ''}`}>
      <div 
        className="sun-effect"
        style={{
          transform: `translate(${scrollY * 0.1}px, ${scrollY * -0.1}px)`
        }}
      />
      <div className="landing-content">
        <h1 className="landing-title">
          <span className="animate-fade-in-up">The First</span>
          <span className="landing-time animate-pulse"> {hours}:{minutes} </span>
        </h1>
        <p className="landing-subtitle animate-fade-in-up">
          Your Early Start to Career Success
        </p>
        <button 
          onClick={handleBeginSearch}
          className="landing-button animate-fade-in-up"
        >
          Begin Your Search
        </button>
      </div>
    </div>
  );
};

export default LandingPage;