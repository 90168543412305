import React from 'react';
import '../App.css';

// Function to format job locations
const formatLocations = (locations) => {
  if (Array.isArray(locations) && locations.length > 3) {
    const firstThree = locations.slice(0, 3);
    return `${firstThree.join(', ')} & more`;
  } else {
    return Array.isArray(locations) ? locations.join(', ') : locations;
  }
};

// Function to calculate the job posting time difference
const formatTimeDifference = (timestamp) => {
  const jobTime = new Date(timestamp);
  const currentTime = new Date();
  const timeDiff = Math.abs(currentTime - jobTime);
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

  // if (hoursDiff <= 6) return "Past 6 hours";
  // if (hoursDiff <= 12) return "Past 12 hours";
  // if (hoursDiff <= 18) return "Past 18 hours";
  // if (hoursDiff <= 24) return "Past 24 hours";
  if (hoursDiff <= 4) return "Past 4 hours";
  if (hoursDiff <= 8) return "Past 8 hours";
  if (hoursDiff <= 12) return "Past 12 hours";
  if (hoursDiff <= 16) return "Past 16 hours";
  if (hoursDiff <= 20) return "Past 20 hours";
  return "Past 24 hours";
};

const JobItem = ({ job }) => {
  const jobLocations = Array.isArray(job.location) ? job.location : [job.location]; // Ensure location is an array

  return (
    <div className="job-card">
      <span className="company-logo-container">
        <img className="company-logo" src={job.logo} alt={`${job.company} logo`} />
      </span>
      <div className="job-header">
        <h2 className="job-title">{job.title}</h2>
        <span className="job-company">{job.company}</span>
      </div>
      <div className="job-info">
        <span className="job-location">
          <svg className="pin-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 2C8.69 2 6 4.69 6 8c0 5.25 6 12 6 12s6-6.75 6-12c0-3.31-2.69-6-6-6zm0 8.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 5.5 12 5.5 14.5 6.62 14.5 8s-1.12 2.5-2.5 2.5z" />
          </svg>
          {formatLocations(jobLocations)}  {/* Use the formatted locations */}
        </span>
        <div className="job-time">
          <svg className="clock-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm.75 12H6.75a.75.75 0 010-1.5h5.25V6a.75.75 0 011.5 0v5.25a.75.75 0 01-.75.75z"/>
          </svg>
          <span className='post-time'>{formatTimeDifference(job.timestamp)}</span>
        </div>
      </div>
      <div className="job-apply">
        <a href={job.link} target="_blank" rel="noopener noreferrer" className="view-button">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default JobItem;
