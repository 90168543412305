import React, { useState, useEffect } from 'react';
import '../HomeBanner.css';
import first24TextImage from '../assets/the-first-24-text.png'

const LandingBanner = ({ onBeginSearch }) => {
  const [scrollY, setScrollY] = useState(0);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);

    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(timer);
    };
  }, []);

  const hours = time.getHours().toString().padStart(2, '0');
  const minutes = time.getMinutes().toString().padStart(2, '0');

  return (
    <div className="home-banner">
      <div 
        className="sun-effect"
        style={{
          transform: `translate(${scrollY * 0.1}px, ${scrollY * -0.1}px)`
        }}
      />
      <div className="home-banner-content">
        <img src={first24TextImage} alt="First24 text" className="home-banner-image animate-fade-in" />
        <p className="home-banner-subtitle animate-fade animation-delay-300">
          Be the early bird | All jobs posted within the last 24 hours
        </p>
        {/* <button 
          onClick={onBeginSearch}
          className="home-banner-button animate-fade-in-up animation-delay-600"
        >
          Begin Your Search
        </button> */}
      </div>
    </div>
  );
};

export default LandingBanner;