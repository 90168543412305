import React from 'react';
import '../HireWithUs.css';
import HireTopTalentImage from '../assets/hire-top-talent.png';
import NavBar from './NavBar';
import Footer from './Footer';


const HireWithUs = () => {
  return (
    <div className="hire-with-us-page">
      <div className="hire-with-us-banner">
        <NavBar />
          <img src={HireTopTalentImage} alt="First24 text" className="hire-banner-image" />
          {/* <h2 className="hire-banner-subtitle">The real job board for real early birds</h2> */}
          {/* <a href="/post-job" className="cta-button">Start Posting Jobs Now</a> */}
      </div>

        <section className="our-audience">
          <h2>Our Audience</h2>
          <p>
              Thousands of tech professionals serious about securing a role they love.
          </p>
          <br></br>
          <p>
              Thousands of tech professionals serious about securing a role they love.
              Our audience is global, but primarily based in North America
          </p>
        </section>

        <section className="how-it-works">
          <h2>How it Works</h2>
          <ol>
            <li>Post a Job in Minutes</li>
            <li>Review Applicants Fast</li>
            <li>Hire Top Talent</li>
          </ol>
        </section>

        <section className="company-message">
          <h2>A message from us</h2>
          <p>
              Thousands of tech professionals serious about securing a role they love.
          </p>
          <br></br>
          <p>
              Thousands of tech professionals serious about securing a role they love.
              Our audience is global, but primarily based in North America
          </p>
        </section>

        <section className="cta-section">
          <h2>Let's chat!</h2>
          <a href="mailto:contact@first24.com" className="cta-button">Contact us</a>
        </section>
      <Footer />
    </div>
  );
};

export default HireWithUs;
