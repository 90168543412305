import React, { useState } from 'react';
import '../App.css';

const JobFilter = ({ onFilterChange }) => {
    const [titleKeyword, setTitleKeyword] = useState('');
    const [location, setLocation] = useState('');

    const handleFilterChange = () => {
        onFilterChange({ titleKeyword, location });
    };

    return (
        <div className="job-filter">
            <div className="filter-group">
                <label htmlFor="titleKeyword">Job Title or Keyword</label>
                <select
                    id="titleKeyword"
                    value={titleKeyword}
                    onChange={(e) => setTitleKeyword(e.target.value)}
                >
                    <option value="">Select Job Title</option>
                    <option value="designer">Designer</option>
                    <option value="iOS">iOS</option>
                    <option value="android">Android</option>
                    <option value="backend">Backend</option>
                    <option value="frontend">Front End</option>
                    <option value="data">Data</option>
                    <option value="program">Program Management</option>
                    <option value="quality">Quality Assurance</option>
                    <option value="hardware">Hardware</option>
                    {/* Add more options */}
                </select>
            </div>

            <div className="filter-group">
                <label htmlFor="location">Location</label>
                <select
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                >
                    <option value="">Select Location</option>
                    <option value="remote">Remote</option>
                    <option value="atlanta">Atlanta</option>
                    <option value="chicago">Chicago</option>
                    <option value="dallas">Dallas</option>
                    <option value="los-angeles">Los Angeles</option>
                    <option value="new-york">New York</option>
                    <option value="san-francisco">San Francisco</option>
                    <option value="seattle">Seattle</option>
                    {/* Add more locations */}
                </select>
            </div>

            <button className="apply-filters-btn" onClick={handleFilterChange}>
                Apply Filters
            </button>
        </div>
    );
};

export default JobFilter;
