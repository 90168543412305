import React from 'react';
import { Twitter, Linkedin, Mail } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-brand">
          <div className="footer-logo">First24</div>
          <p className="footer-description">
            Connecting early birds with their dream opportunities. The premier platform for morning people in tech.
          </p>
        </div>

        <div className="footer-section">
          <div className="footer-links">
            <h3 className="footer-links-title">Company</h3>
            <a href="/about">About Us</a>
            <a href="/careers">Careers</a>
            <a href="/blog">Blog</a>
            <a href="/press">Press</a>
          </div>
        </div>

        <div className="footer-section">
          <div className="footer-links">
            <h3 className="footer-links-title">Resources</h3>
            <a href="/support">Support</a>
            <a href="/terms">Terms of Service</a>
            <a href="/privacy">Privacy Policy</a>
            <a href="/cookie-policy">Cookie Policy</a>
          </div>
        </div>

        <div className="footer-section">
          <div className="footer-links">
            <h3 className="footer-links-title">Connect</h3>
            <a href="mailto:contact@first24.com">Contact Us</a>
            <a href="/feedback">Feedback</a>
            <div className="footer-social">
              <a href="https://twitter.com" className="social-icon">
                <Twitter size={18} color="#A0AEC0" />
              </a>
              <a href="https://linkedin.com" className="social-icon">
                <Linkedin size={18} color="#A0AEC0" />
              </a>
              <a href="mailto:contact@first24.com" className="social-icon">
                <Mail size={18} color="#A0AEC0" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} First24. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;